<template>
  <v-container fluid>
    <Breadcrumbs />
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 color="transparent">
        <v-row style="margin: 0px" color="transparent">
          <v-col class="col-12" sm="12" md="12">
            <v-card class="secondarygrad rounded ma-md-10 pa-md-10">
              <v-card-text style="padding: 20px">
                <div class="row wrap" style="padding: 10px">
                  <v-flex
                    xs12
                    sm12
                    md12
                    style="
                      padding: 0px 25px 7px 25px;
                      text-align: center !important;
                    "
                  >
                    <v-dialog v-model="cropDialog" width="500">
                      <v-card style="padding: 2%">
                        <v-card-text style="margin: 2%">
                          <VueCropper
                            v-show="selectedFile"
                            ref="cropper"
                            :src="selectedFile"
                            :aspect-ratio="1"
                            :zoomable="false"
                            :scalable="false"
                            alt="Source Image"
                          ></VueCropper>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            class="primarygrad"
                            @click="saveImage(), (cropDialog = false)"
                            >Crop</v-btn
                          >
                          <v-btn
                            color="primary"
                            text
                            @click="cropDialog = false"
                            >Cancel</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <img
                      :src="logourl"
                      alt=""
                      style="
                        width: 200px;
                        height: 200px;
                        margin-bottom: 25px;
                        border-radius: 50%;
                      "
                    />
                    <v-file-input
                      ref="file"
                      v-model="uploadedLogo"
                      style="display: none"
                      @change="onFileSelect($event)"
                    ></v-file-input>
                    <!-- <input
                        ref="file"
                        type="file"
                        style="display: none"
                        @change="uploadFile($event)"
                      /> -->

                    <!-- <button>Upload</button>  -->

                    <div>
                      <span
                        ><v-btn
                          color="primary"
                          outlined
                          text
                          @click="$refs.file.$refs.input.click()"
                          >Upload</v-btn
                        >
                        <!-- <v-btn
                            color="primary"
                            outlined
                            text
                            @click="$refs.file.click()"
                            >Upload</v-btn
                          > -->
                      </span>
                      <span style="padding-left: 2%"
                        ><v-btn
                          color="error"
                          outlined
                          text
                          @click="removeImage()"
                          >Remove</v-btn
                        ></span
                      >
                    </div>

                    <!-- <v-file-input
                            ref="file"
                            style="display: none"
                            @change="uploadFile($event)"
                            outlined
                            dense 
                            :clearable="false"
                          ></v-file-input>

                          <button @click="dialogOpen">open file dialog</button>   -->
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="orgName"
                      outlined
                      dense
                      label="Name"
                      class="my-5"
                      hide-details="true"
                      clearable
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="orgAddress"
                      outlined
                      dense
                      label="Address"
                      class="mb-5"
                      hide-details="true"
                      clearable
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="orgAddress2"
                      outlined
                      dense
                      label="Address Line 2"
                      class="mb-5"
                      hide-details="true"
                      clearable
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="orgWebsite"
                      outlined
                      dense
                      label="Website"
                      class="mb-5"
                      hide-details="true"
                      clearable
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="orgDesp"
                      outlined
                      dense
                      label="Description"
                      class="mb-5"
                      hide-details="true"
                      clearable
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-autocomplete
                      v-model="country"
                      dense
                      :items="countries"
                      label="Select Country"
                      class="mb-5"
                      hide-details="true"
                      required
                      item-text="name"
                      item-value="name"
                      outlined
                      @change="updateCurrency()"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-autocomplete
                      v-model="currency"
                      dense
                      :items="currencies"
                      label="Select Currency"
                      class="mb-5"
                      hide-details="true"
                      required
                      item-text="symbol"
                      item-value="symbol"
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.symbol">
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.currencyCode"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="menuStart"
                      v-model="menuStart"
                      :return-value.sync="startTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startTime"
                          label="Office Start Time"
                          placeholder="10:00"
                          dense
                          outlined
                          hide-details="true"
                          readonly
                          class="mb-5 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuStart"
                        v-model="startTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuStart = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuStart.save(startTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-dialog
                      ref="menuEnd"
                      v-model="menuEnd"
                      :return-value.sync="endTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endTime"
                          label="Office End Time"
                          placeholder="18:00"
                          dense
                          outlined
                          hide-details="true"
                          readonly
                          class="mb-5 ml-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuEnd"
                        v-model="endTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuEnd = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuEnd.save(endTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm12 md12 style="text-align: center">
                    <v-btn color="primarygrad white--text" @click="update"
                      >Update</v-btn
                    >
                  </v-flex>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin: 0px" color="transparent">
          <v-col class="col-12" sm="12" md="12">
            <v-card
              class="secondarygrad rounded ma-md-10 pa-md-10"
              elevation="6"
            >
              <v-card-title>KYC Details</v-card-title>

              <v-card-text>
                <b
                  >Please upload 2 of the following documents to verify your
                  account with us</b
                >
                <div class="custom-flex">
                  <p>Personally Identity document</p>
                  <p>(Any One)</p>
                </div>

                <v-radio-group
                  v-model="selectedPersonalIdentity"
                  row
                  style="margin-top: -10px;"
                  :disabled="
                    companyDetails.kycDetails &&
                      companyDetails.kycDetails.kycVerified
                  "
                >
                  <v-radio label="Aadhar Card" value="Aadhar Card"></v-radio>

                  <v-radio label="PAN" value="PAN"></v-radio>

                  <v-radio label="Voter Card" value="Voter Card"></v-radio>

                  <v-radio
                    label="Driving License"
                    value="Driving License"
                  ></v-radio>

                  <v-radio label="Passport" value="Passport"></v-radio>
                </v-radio-group>

                <v-text-field
                  v-model="personalIdentityNumber"
                  outlined
                  dense
                  :label="selectedPersonalIdentity"
                  class="mb-5"
                  hide-details="true"
                  clearable
                  :disabled="
                    companyDetails.kycDetails &&
                      companyDetails.kycDetails.kycVerified
                  "
                >
                </v-text-field>

                <v-container>
                  <v-row justify="center">
                    <v-col cols="auto" align="center">
                      <div v-if="existingKycFile" class="kyc-img-container">
                        <img :src="kycImageUrl" alt="" />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <v-flex
                  v-if="
                    !companyDetails.kycDetails ||
                      (companyDetails.kycDetails &&
                        !companyDetails.kycDetails.kycVerified)
                  "
                >
                  <v-file-input
                    label=""
                    accept="image/*"
                    outlined
                    dense
                    class="mb-5"
                    @change="onFileSelect2"
                  ></v-file-input>
                </v-flex>

                <b></b>
                <div class="custom-flex">
                  <p>Company Registration Information</p>
                  <p>(Any One)</p>
                </div>

                <v-radio-group
                  v-model="selectedCompanyRegistration"
                  row
                  style="margin-top: -10px;"
                  :disabled="
                    companyDetails.kycDetails &&
                      companyDetails.kycDetails.kycVerified
                  "
                >
                  <v-radio
                    label="GST Certificate"
                    value="GST Certificate"
                  ></v-radio>

                  <v-radio label="MoA" value="MoA"></v-radio>

                  <v-radio
                    label="Trade License"
                    value="Trade License"
                  ></v-radio>

                  <v-radio label="Udyam" value="Udyam"></v-radio>

                  <!-- <v-radio label="Passport" value="Passport"></v-radio> -->
                </v-radio-group>

                <v-text-field
                  v-model="companyRegistrationNumber"
                  outlined
                  dense
                  :label="selectedCompanyRegistration"
                  class="mb-5"
                  hide-details="true"
                  clearable
                  :disabled="
                    companyDetails.kycDetails &&
                      companyDetails.kycDetails.kycVerified
                  "
                >
                </v-text-field>

                <v-container>
                  <v-row justify="center">
                    <v-col cols="auto" align="center">
                      <div v-if="existingKycFile2" class="kyc-img-container">
                        <img :src="kycImageUrl2" alt="" />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <v-flex
                  v-if="
                    !companyDetails.kycDetails ||
                      (companyDetails.kycDetails &&
                        !companyDetails.kycDetails.kycVerified)
                  "
                >
                  <v-file-input
                    label=""
                    accept="image/*"
                    outlined
                    dense
                    class="mb-5"
                    @change="onFileSelect3"
                  ></v-file-input>
                </v-flex>

                <!-- <div class="custom-flex"> -->

                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="trade_license_number"
                    outlined
                    dense
                    label="Trade License Number"
                    class="mb-5"
                    hide-details="true"
                    clearable
                    :disabled="
                      companyDetails.kycDetails &&
                        companyDetails.kycDetails.kycVerified
                    "
                  >
                  </v-text-field>
                </v-flex> -->

                <v-flex
                  v-if="
                    !companyDetails.kycDetails ||
                      (companyDetails.kycDetails &&
                        !companyDetails.kycDetails.kycVerified)
                  "
                  xs12
                  sm12
                  md12
                  style="text-align: center"
                >
                  <v-btn color="primarygrad white--text" @click="updateKyc"
                    >Save</v-btn
                  >
                </v-flex>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin: 0px" color="transparent">
          <v-col class="col-12" sm="12" md="12">
            <v-card
              class="secondarygrad rounded ma-md-10 pa-md-10"
              elevation="6"
            >
              <v-card-title>Unique Contacts</v-card-title>
              <v-card-subtitle
                >Define how contacts are unique across the
                system</v-card-subtitle
              >

              <v-card-text>
                <div class="row wrap" style="padding: 10px">
                  <v-flex xs12 sm12 md12>
                    <v-radio-group v-model="selectedContactUnique">
                      <v-radio
                        v-for="(u, index) in uniqueByList"
                        :key="index"
                        :label="u.label"
                        :value="u.value"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex xs12 sm12 md12 style="text-align: center">
                    <v-btn color="primarygrad white--text" @click="update"
                      >Update</v-btn
                    >
                  </v-flex>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog v-model="loader" persistent width="400">
          <v-card color="primarygrad" dark>
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="loader = false">CANCEL</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import countries from "../js/countries";
import compress from "compress-base64";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Breadcrumbs from "../components/breadcrumbs.vue";

export default {
  components: { VueCropper, Breadcrumbs },
  data() {
    return {
      personalIdentityNumber: "",
      companyRegistrationNumber: "",
      selectedPersonalIdentity: "Aadhar Card",
      selectedCompanyRegistration: "GST Certificate",
      trade_license_number: "",
      pan_number: "",
      gst_number: "",
      imageFile: "",
      previewImage: "",
      fileToBeUploaded: {},
      orgName: "",
      orgAddress: "",
      orgAddress2: "",
      orgWebsite: "",
      orgDesp: "",
      loader: false,
      countries: countries,
      country: "",
      currency: "",
      currencies: [],
      attachmentsId: "",
      uploadedLogo: null,
      logourl: null,
      logoId: "",
      cropDialog: false,
      selectedFile: null,
      menuStart: false,
      menuEnd: false,
      startTime: null,
      endTime: null,
      selectedContactUnique: { label: "List", value: "list" },
      uniqueByList: [
        {
          label:
            "My contacts should be unique in each list, but can be duplicate in other lists",
          value: "list",
        },
        { label: "My contacts are unique across the system", value: "company" },
      ],
      kycFile: null,
      kycFile2: null,
      existingKycFile: null,
      existingKycFile2: null,
      kycImageUrl: null,
      kycImageUrl2: null,
    };
  },
  computed: {
    ...mapState(["ENDPOINT"]),
    ...mapGetters(["ENDPOINT", "companyDetails"]),
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
  },
  async created() {
    try {
      await this.refresh();
      console.log("existingKycFile: ", this.existingKycFile);
      console.log("existingKycFile: ", this.existingKycFile2);
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    onFileSelect2(file) {
      this.kycFile = file;
    },
    onFileSelect3(file) {
      this.kycFile2 = file;
    },
    async updateKyc() {
      var self = this;

      if (self.existingKycFile == null && self.kycFile == null) {
        return self.$swal({
          type: "error",
          text:
            "Please upload a valid Personally Identity document to continue",
        });
      }

      // if (self.existingKycFile2 == null && self.kycFile2 == null) {
      //   return self.$swal({
      //     type: "error",
      //     text:
      //       "Please upload a valid Company Registration Information to continue",
      //   });
      // }

      let attachment, attachment2;

      if (
        (self.existingKycFile != null && self.kycFile != null) ||
        (self.existingKycFile == null && self.kycFile != null)
      ) {
        var formData = null;
        formData = new FormData();
        formData.append("file", this.kycFile);
        formData.append("type", "kyc");
        formData.append("companyId", this.$store.state.companyDetails.id);
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: this.$store.state.user.token,
          },
        };
        attachment = await this.$http.post(
          `${this.$store.state.ENDPOINT}/attachments/upload`,
          formData,
          config
        );

        attachment = attachment.body;
      }

      if (
        (self.existingKycFile2 != null && self.kycFile2 != null) ||
        (self.existingKycFile2 == null && self.kycFile2 != null)
      ) {
        var formData2 = null;
        formData2 = new FormData();
        formData2.append("file", this.kycFile2);
        formData2.append("type", "kyc");
        formData2.append("companyId", this.$store.state.companyDetails.id);
        var config2 = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: this.$store.state.user.token,
          },
        };
        attachment2 = await this.$http.post(
          `${this.$store.state.ENDPOINT}/attachments/upload`,
          formData2,
          config2
        );

        attachment2 = attachment2.body;
      }

      // https://storage.cloud.google.com/godial-enterprise.appspot.com/${attachment.name}

      // let fileDetails, fileDetails2;

      // if (self.existingKycFile != null) {
      //   if (self.kycFile != null) {
      //     fileDetails = attachment;
      //   } else {
      //     fileDetails = self.existingKycFile;
      //   }
      // } else {
      //   fileDetails = attachment;
      // }

      // var kycDetails = {
      //   trade_license_number: this.trade_license_number,
      //   pan_number: this.pan_number,
      //   gst_number: this.gst_number,
      //   fileDetails:
      //     self.existingKycFile != null
      //       ? self.kycFile != null
      //         ? attachment
      //         : self.existingKycFile
      //       : attachment,
      //   fileDetails2:
      //     self.existingKycFile2 != null
      //       ? self.kycFile2 != null
      //         ? attachment2
      //         : self.existingKycFile2
      //       : attachment2,
      //   kycVerified: false,
      // };

      var kycDetails = {
        kycVerified: false,
        personalIdentity: {
          type: this.selectedPersonalIdentity,
          number: this.personalIdentityNumber,
          fileDetails:
            self.existingKycFile != null
              ? self.kycFile != null
                ? attachment
                : self.existingKycFile
              : attachment,
        },
        companyRegistration: {
          type: this.selectedCompanyRegistration,
          number: this.companyRegistrationNumber,
          fileDetails:
            self.existingKycFile2 != null
              ? self.kycFile2 != null
                ? attachment2
                : self.existingKycFile2
              : attachment2,
        },
      };

      console.log("kycDetails", kycDetails);

      self.$http
        .patch(
          `${self.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          {
            kycDetails: kycDetails,
          }
        )
        .then(async (response) => {
          const sendMail = await self.$http.post(
            `${self.ENDPOINT}/companies/sendEmail`
          );
          self.$swal({
            type: "success",
            text: "KYC details updated successfully",
          });
          self.refresh();
        });
    },
    // Load this page
    async refresh() {
      try {
        this.currencies = countries.filter((x) => {
          return x.symbol;
        });
        var filter = {
          include: [{ relation: "plan", scope: { include: ["coupons"] } }],
        };
        var response = await this.$http.get(
          `${this.$store.state.ENDPOINT}/companies/${
            this.$store.getters.user.companyId
          }?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
        response = response.body;
        console.log("response.body", response);
        this.$store.commit("setCompany", response); // store this company details
        this.orgName = response.name;
        this.orgAddress = response.address;
        this.orgAddress2 = response.addressLine2;
        this.orgWebsite = response.website;
        this.orgDesp = response.description;
        this.country = response.country;
        this.currency = response.currency;
        this.startTime = this.$moment(response.officeStartTime, "HH:mm").format(
          "HH:mm"
        );
        this.endTime = this.$moment(response.officeEndTime, "HH:mm").format(
          "HH:mm"
        );

        this.selectedContactUnique = this.uniqueByList.find((u) => {
          return u.value == response.contactUniqueBy;
        }).value;

        this.logoId =
          typeof response.logoId != "undefined" ? response.logoId : "";
        this.logourl = await this.getImage(response);

        // this.trade_license_number = response.kycDetails.trade_license_number;
        // this.pan_number = response.kycDetails.pan_number;
        // this.gst_number = response.kycDetails.gst_number;
        // this.existingKycFile = response.kycDetails.fileDetails;
        // this.existingKycFile2 = response.kycDetails.fileDetails2;

        this.selectedPersonalIdentity =
          response.kycDetails.personalIdentity.type;
        this.personalIdentityNumber =
          response.kycDetails.personalIdentity.number;
        this.existingKycFile = response.kycDetails.personalIdentity.fileDetails;

        this.selectedCompanyRegistration =
          response.kycDetails.companyRegistration.type;
        this.companyRegistrationNumber =
          response.kycDetails.companyRegistration.number;
        this.existingKycFile2 =
          response.kycDetails.companyRegistration.fileDetails;

        this.kycImageUrl = await this.getKycImage(this.existingKycFile);
        this.kycImageUrl2 = await this.getKycImage(this.existingKycFile2);
      } catch (e) {
        console.log("Error", e);
      }
    },
    // get image src uri
    async getImage(src) {
      if (src.logo != "" && "logo" in src) {
        return `${this.$store.state.ENDPOINT}/storages/get/${src.logo}?access_token=${this.$store.getters.user.token}`;
      } else {
        return require("../assets/logo.png");
      }
    },

    // get image src uri
    async getKycImage(kycFile) {
      if (kycFile.name != "") {
        return `${this.$store.state.ENDPOINT}/storages/get/${kycFile.name}?access_token=${this.$store.getters.user.token}`;
      } else {
        return require("../assets/logo.png");
      }
    },

    async removeImage() {
      if (this.companyDetails.logo) {
        try {
          await this.$http.delete(
            `${this.$store.state.ENDPOINT}/attachments/${this.companyDetails.logoId}`
          );

          await this.$http.patch(
            `${this.$store.state.ENDPOINT}/companies/` +
              this.$store.state.companyDetails.id,
            {
              logoId: "",
              logo: "",
            }
          );
          this.$swal({
            type: "success",
            text: "Logo Removed",
          });
          this.refresh();
        } catch (err) {
          console.log(err);
          if (err.body.error && err.body.error.message) {
            return this.$swal({ type: "error", text: err.body.error.message });
          }
          this.$swal({ type: "error", text: "Something went wrong" });
        }
      }

      //---------------- Not Used ----------------------//
      // if (this.logourl != "" && this.logoId != null) {
      //   console.log("id:", this.logoId);
      //   this.$http
      //     .delete(`${this.ENDPOINT}/dbattachments/${this.logoId}`)
      //     .then((res) => {
      //       this.$swal({
      //         type: "success",
      //         text: "Logo Removed",
      //       });
      //       this.refresh();
      //     })
      //     .catch((err) => {
      //       this.$swal({
      //         type: "error",
      //         text: "Something went wrong. Try again later",
      //       });
      //     });
      // }
    },
    updateCurrency() {
      let temp = countries.filter((x) => {
        return x.name === this.country;
      });
      if (temp.length > 0) this.currency = temp[0].symbol;
      else this.currency = "₹";
    },
    dialogOpen() {
      this.$refs.file.$el.click();
    },
    async uploadFile(file) {
      try {
        this.loader = true;
        var formData = new FormData();
        formData.append("file", file);
        formData.append("type", "logo");
        formData.append("companyId", this.$store.state.companyDetails.id);
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: this.$store.state.user.token,
          },
        };

        //delete previous logo
        if (this.companyDetails.logo) {
          await this.$http.delete(
            `${this.$store.state.ENDPOINT}/attachments/${this.companyDetails.logoId}`
          );
        }

        //store the file to storage
        var attachment = await this.$http.post(
          `${this.$store.state.ENDPOINT}/attachments/upload`,
          formData,
          config
        );
        attachment = attachment.body;

        await this.$http.patch(
          `${this.$store.state.ENDPOINT}/companies/` +
            this.$store.state.companyDetails.id,
          {
            logoId: attachment.id,
            logo: attachment.name,
          }
        );

        setTimeout(() => {
          this.refresh();
          this.loader = false;
          this.$swal({
            type: "success",
            text: "Logo Updated",
          });
        }, 5000);
      } catch (e) {
        this.loader = false;
      }
    },
    update() {
      const self = this;
      var payload = {};

      var startTime = self.$moment(self.startTime, "HH:mm").format("HH:mm");
      var endTime = self.$moment(self.endTime, "HH:mm").format("HH:mm");

      console.log("start time", startTime);

      self.orgName = self.orgName.trim();

      self.orgAddress = self.orgAddress ? self.orgAddress.trim() : "";
      self.orgAddress2 = self.orgAddress2 ? self.orgAddress2.trim() : "";

      if (startTime != "Invalid date" && endTime != "Invalid date") {
        if (
          self
            .$moment(endTime, "HH:mm")
            .isAfter(self.$moment(startTime, "HH:mm")) == false
        ) {
          self.loader = false;
          return self.$swal({
            type: "warning",
            text: "End time must be greater than start time",
          });
        }
      }

      if (self.orgName == "") {
        self.loader = false;
        return self.$swal({
          type: "warning",
          text: "Please add a organization name.",
        });
      }

      if (self.orgName.length > 20) {
        self.$swal({
          type: "warning",
          text: "Please Keep the Organization Name under 20 characters",
        });
        return;
      }

      if (self.orgAddress.length > 25) {
        self.$swal({
          type: "warning",
          text: "Please Keep the Address under 25 characters",
        });
        return;
      }

      if (self.orgAddress2.length > 25) {
        self.$swal({
          type: "warning",
          text: "Please Keep the Address Line 2 under 25 characters",
        });
        return;
      }

      payload.name = self.orgName;
      payload.address = self.orgAddress || "";
      payload.addressLine2 = self.orgAddress2 || "";
      payload.website = self.orgWebsite || "";
      payload.description = self.orgDesp || "";
      payload.country = self.country;
      payload.currency = self.currency;

      if (startTime != "Invalid date" && endTime != "Invalid date") {
        payload.officeStartTime = startTime;
        payload.officeEndTime = endTime;
      }

      if (typeof self.selectedContactUnique != "object") {
        payload.contactUniqueBy = self.selectedContactUnique;
      }
      self.loader = true;

      self.$http
        .patch(
          `${self.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          payload
        )
        .then((response) => {
          self.$swal({ type: "success", text: "Successful" });
          self.refresh();
        })
        .catch((err) => {
          self.$swal({
            type: "error",
            text: "Something went wrong. Try again later",
          });
        });

      self.loader = false;
    },
    onFileSelect(file) {
      this.mime_type = file.type;
      this.fileName = file.name;
      if (typeof FileReader === "function") {
        this.cropDialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    saveImage() {
      const self = this;
      const compressor = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            compress(event.target.result, {
              width: 512,
              type: "image/png", // default
              max: 2048, // max size
              min: 2, // min size
              quality: 0.5,
            })
              .then((result) => {
                resolve(result);
              })
              .catch((e) => {
                console.log("err", e);
              });
          };
          reader.onerror = (error) => reject(error);
        });

      function srcToFile(src, fileName, mimeType) {
        return fetch(src)
          .then(function(res) {
            return res.arrayBuffer();
          })
          .then(function(buf) {
            return new File([buf], fileName, { type: mimeType });
          });
      }

      var uncompressed = self.$refs.cropper.getCroppedCanvas().toDataURL();

      srcToFile(uncompressed, self.fileName, "image/png").then(async (file) => {
        var compressed = await compressor(file);
        self.logourl = compressed;
        srcToFile(self.logourl, self.fileName, "image/png").then(
          async (file2Upload) => {
            self.uploadFile(file2Upload);
          }
        );

        // -------------------------- Not Used --------------------- //
        //{
        // var payload2 = {
        //   data: self.logourl,
        //   name: self.fileName,
        //   companyId: self.$store.getters.user.companyId,
        // };
        // if (
        //   self.logoId != null &&
        //   typeof self.logoId != "undefined" &&
        //   self.logoId != ""
        // ) {
        //   payload2.id = self.logoId;
        // }
        // console.log("pay2", payload2);
        // self.$http
        //   .put(`${self.$store.state.ENDPOINT}/dbattachments/`, payload2)
        //   .then((response) => {
        //     // console.log("response body",response.body)
        //     self.$swal({ type: "success", text: "Successful" });
        //     self.refresh();
        //   })
        //   .catch((err) => {
        //     self.$swal({
        //       type: "error",
        //       text: "Something went wrong. Try again later",
        //     });
        //   });
        // }
      });
    },
  },
};
</script>
<style scoped>
.v-divider {
  margin: 5px 0px 25px 0px;
}

.kyc-data-container {
  width: 80%;
}

.kyc-img-container {
  width: 80%;
}

.custom-flex {
  display: flex;
}

.kyc-img-container img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

@media only screen and (max-width: 700px) {
  .kyc-data-container {
    width: 100%;
  }

  .custom-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .kyc-img-container {
    width: 100%;
    margin: 0.5rem auto;
  }
}
</style>
