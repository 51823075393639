<template>
  <v-card elevation="0">
    <v-card-text style="padding: 6px">
      <v-breadcrumbs :items="pages">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            @click="$router.push(item.path)"
            :link="item != pages[pages.length - 1]"
            :disabled="item == pages[pages.length - 1]"
            style="font-size: 12px"
          >
            <u v-if="item != pages[pages.length - 1]">{{ item.name }}</u>
            <em v-else>{{ item.name }}</em>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      paths: [
        {
          path: "/home",
          name: "Home",
          meta: {
            requiresAuth: true,
            title: "Dashboard",
          },
        },
        {
          path: "/settings",
          name: "Settings",
          meta: {
            requiresAuth: true,
            title: "Settings",
          },
        },
      ],
    };
  },
  computed: {
    pages() {
      if (this.$router.currentRoute.name == "Preview Template") {
        return this.paths.concat([
          {
            path: "/templates",
            name: "Message Templates",
            meta: {
              subPage: true,
              requiresAuth: true,
              title: "Message Templates",
            },
          },
          {
            path: this.$router.currentRoute.path,
            name: this.$router.currentRoute.name,
            meta: this.$router.currentRoute.meta,
          },
        ]);
      } else {
        return this.paths.concat([
          {
            path: this.$router.currentRoute.path,
            name: this.$router.currentRoute.name,
            meta: this.$router.currentRoute.meta,
          },
        ]);
      }
    },
  },
};
</script>
