export default [
  { "name": "Andorra", "code": "AD", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "United Arab Emirates",
    "code": "AE",
    "currencyCode": "AED",
    "symbol": "AED"
  },
  {
    "name": "Afghanistan",
    "code": "AF",
    "currencyCode": "AFN",
    "symbol": "AFN"
  },
  {
    "name": "Antigua and Barbuda",
    "code": "AG",
    "currencyCode": "XCD",
    "symbol": "EC$"
  },
  { "name": "Anguilla", "code": "AI", "currencyCode": "XCD", "symbol": "EC$" },
  { "name": "Albania", "code": "AL", "currencyCode": "ALL", "symbol": "ALL" },
  { "name": "Armenia", "code": "AM", "currencyCode": "AMD", "symbol": "AMD" },
  { "name": "Angola", "code": "AO", "currencyCode": "AOA", "symbol": "AOA" },
  { "name": "Antarctica", "code": "AQ", "currencyCode": "" },
  { "name": "Argentina", "code": "AR", "currencyCode": "ARS", "symbol": "ARS" },
  {
    "name": "American Samoa",
    "code": "AS",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Austria", "code": "AT", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Australia", "code": "AU", "currencyCode": "AUD", "symbol": "A$" },
  { "name": "Aruba", "code": "AW", "currencyCode": "AWG", "symbol": "AWG" },
  { "name": "Åland", "code": "AX", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Azerbaijan",
    "code": "AZ",
    "currencyCode": "AZN",
    "symbol": "AZN"
  },
  {
    "name": "Bosnia and Herzegovina",
    "code": "BA",
    "currencyCode": "BAM",
    "symbol": "BAM"
  },
  { "name": "Barbados", "code": "BB", "currencyCode": "BBD", "symbol": "BBD" },
  {
    "name": "Bangladesh",
    "code": "BD",
    "currencyCode": "BDT",
    "symbol": "BDT"
  },
  { "name": "Belgium", "code": "BE", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Burkina Faso",
    "code": "BF",
    "currencyCode": "XOF",
    "symbol": "CFA"
  },
  { "name": "Bulgaria", "code": "BG", "currencyCode": "BGN", "symbol": "BGN" },
  { "name": "Bahrain", "code": "BH", "currencyCode": "BHD", "symbol": "BHD" },
  { "name": "Burundi", "code": "BI", "currencyCode": "BIF", "symbol": "BIF" },
  { "name": "Benin", "code": "BJ", "currencyCode": "XOF", "symbol": "CFA" },
  {
    "name": "Saint Barthélemy",
    "code": "BL",
    "currencyCode": "EUR",
    "symbol": "€"
  },
  { "name": "Bermuda", "code": "BM", "currencyCode": "BMD", "symbol": "BMD" },
  { "name": "Brunei", "code": "BN", "currencyCode": "BND", "symbol": "BND" },
  { "name": "Bolivia", "code": "BO", "currencyCode": "BOB", "symbol": "BOB" },
  { "name": "Bonaire", "code": "BQ", "currencyCode": "USD", "symbol": "$" },
  { "name": "Brazil", "code": "BR", "currencyCode": "BRL", "symbol": "R$" },
  { "name": "Bahamas", "code": "BS", "currencyCode": "BSD", "symbol": "BSD" },
  { "name": "Bhutan", "code": "BT", "currencyCode": "BTN", "symbol": "BTN" },
  {
    "name": "Bouvet Island",
    "code": "BV",
    "currencyCode": "NOK",
    "symbol": "NOK"
  },
  { "name": "Botswana", "code": "BW", "currencyCode": "BWP", "symbol": "BWP" },
  { "name": "Belarus", "code": "BY", "currencyCode": "BYR", "symbol": "BYR" },
  { "name": "Belize", "code": "BZ", "currencyCode": "BZD", "symbol": "BZD" },
  { "name": "Canada", "code": "CA", "currencyCode": "CAD", "symbol": "CA$" },
  {
    "name": "Cocos [Keeling] Islands",
    "code": "CC",
    "currencyCode": "AUD",
    "symbol": "A$"
  },
  {
    "name": "Democratic Republic of the Congo",
    "code": "CD",
    "currencyCode": "CDF",
    "symbol": "CDF"
  },
  {
    "name": "Central African Republic",
    "code": "CF",
    "currencyCode": "XAF",
    "symbol": "FCFA"
  },
  {
    "name": "Republic of the Congo",
    "code": "CG",
    "currencyCode": "XAF",
    "symbol": "FCFA"
  },
  {
    "name": "Switzerland",
    "code": "CH",
    "currencyCode": "CHF",
    "symbol": "CHF"
  },
  {
    "name": "Ivory Coast",
    "code": "CI",
    "currencyCode": "XOF",
    "symbol": "CFA"
  },
  {
    "name": "Cook Islands",
    "code": "CK",
    "currencyCode": "NZD",
    "symbol": "NZ$"
  },
  { "name": "Chile", "code": "CL", "currencyCode": "CLP", "symbol": "CLP" },
  { "name": "Cameroon", "code": "CM", "currencyCode": "XAF", "symbol": "FCFA" },
  { "name": "China", "code": "CN", "currencyCode": "CNY", "symbol": "CN¥" },
  { "name": "Colombia", "code": "CO", "currencyCode": "COP", "symbol": "COP" },
  {
    "name": "Costa Rica",
    "code": "CR",
    "currencyCode": "CRC",
    "symbol": "CRC"
  },
  { "name": "Cuba", "code": "CU", "currencyCode": "CUP", "symbol": "CUP" },
  {
    "name": "Cape Verde",
    "code": "CV",
    "currencyCode": "CVE",
    "symbol": "CVE"
  },
  { "name": "Curacao", "code": "CW", "currencyCode": "ANG", "symbol": "ANG" },
  {
    "name": "Christmas Island",
    "code": "CX",
    "currencyCode": "AUD",
    "symbol": "A$"
  },
  { "name": "Cyprus", "code": "CY", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Czechia", "code": "CZ", "currencyCode": "CZK", "symbol": "CZK" },
  { "name": "Germany", "code": "DE", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Djibouti", "code": "DJ", "currencyCode": "DJF", "symbol": "DJF" },
  { "name": "Denmark", "code": "DK", "currencyCode": "DKK", "symbol": "DKK" },
  { "name": "Dominica", "code": "DM", "currencyCode": "XCD", "symbol": "EC$" },
  {
    "name": "Dominican Republic",
    "code": "DO",
    "currencyCode": "DOP",
    "symbol": "DOP"
  },
  { "name": "Algeria", "code": "DZ", "currencyCode": "DZD", "symbol": "DZD" },
  { "name": "Ecuador", "code": "EC", "currencyCode": "USD", "symbol": "$" },
  { "name": "Estonia", "code": "EE", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Egypt", "code": "EG", "currencyCode": "EGP", "symbol": "EGP" },
  {
    "name": "Western Sahara",
    "code": "EH",
    "currencyCode": "MAD",
    "symbol": "MAD"
  },
  { "name": "Eritrea", "code": "ER", "currencyCode": "ERN", "symbol": "ERN" },
  { "name": "Spain", "code": "ES", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Ethiopia", "code": "ET", "currencyCode": "ETB", "symbol": "ETB" },
  { "name": "Finland", "code": "FI", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Fiji", "code": "FJ", "currencyCode": "FJD", "symbol": "FJD" },
  {
    "name": "Falkland Islands",
    "code": "FK",
    "currencyCode": "FKP",
    "symbol": "FKP"
  },
  { "name": "Micronesia", "code": "FM", "currencyCode": "USD", "symbol": "$" },
  {
    "name": "Faroe Islands",
    "code": "FO",
    "currencyCode": "DKK",
    "symbol": "DKK"
  },
  { "name": "France", "code": "FR", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Gabon", "code": "GA", "currencyCode": "XAF", "symbol": "FCFA" },
  {
    "name": "United Kingdom",
    "code": "GB",
    "currencyCode": "GBP",
    "symbol": "£"
  },
  { "name": "Grenada", "code": "GD", "currencyCode": "XCD", "symbol": "EC$" },
  { "name": "Georgia", "code": "GE", "currencyCode": "GEL", "symbol": "GEL" },
  {
    "name": "French Guiana",
    "code": "GF",
    "currencyCode": "EUR",
    "symbol": "€"
  },
  { "name": "Guernsey", "code": "GG", "currencyCode": "GBP", "symbol": "£" },
  { "name": "Ghana", "code": "GH", "currencyCode": "GHS", "symbol": "GHS" },
  { "name": "Gibraltar", "code": "GI", "currencyCode": "GIP", "symbol": "GIP" },
  { "name": "Greenland", "code": "GL", "currencyCode": "DKK", "symbol": "DKK" },
  { "name": "Gambia", "code": "GM", "currencyCode": "GMD", "symbol": "GMD" },
  { "name": "Guinea", "code": "GN", "currencyCode": "GNF", "symbol": "GNF" },
  { "name": "Guadeloupe", "code": "GP", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Equatorial Guinea",
    "code": "GQ",
    "currencyCode": "XAF",
    "symbol": "FCFA"
  },
  { "name": "Greece", "code": "GR", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "code": "GS",
    "currencyCode": "GBP",
    "symbol": "£"
  },
  { "name": "Guatemala", "code": "GT", "currencyCode": "GTQ", "symbol": "GTQ" },
  { "name": "Guam", "code": "GU", "currencyCode": "USD", "symbol": "$" },
  {
    "name": "Guinea-Bissau",
    "code": "GW",
    "currencyCode": "XOF",
    "symbol": "CFA"
  },
  { "name": "Guyana", "code": "GY", "currencyCode": "GYD", "symbol": "GYD" },
  { "name": "Hong Kong", "code": "HK", "currencyCode": "HKD", "symbol": "HK$" },
  {
    "name": "Heard Island and McDonald Islands",
    "code": "HM",
    "currencyCode": "AUD",
    "symbol": "A$"
  },
  { "name": "Honduras", "code": "HN", "currencyCode": "HNL", "symbol": "HNL" },
  { "name": "Croatia", "code": "HR", "currencyCode": "HRK", "symbol": "HRK" },
  { "name": "Haiti", "code": "HT", "currencyCode": "HTG", "symbol": "HTG" },
  { "name": "Hungary", "code": "HU", "currencyCode": "HUF", "symbol": "HUF" },
  { "name": "Indonesia", "code": "ID", "currencyCode": "IDR", "symbol": "IDR" },
  { "name": "Ireland", "code": "IE", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Israel", "code": "IL", "currencyCode": "ILS", "symbol": "₪" },
  { "name": "Isle of Man", "code": "IM", "currencyCode": "GBP", "symbol": "£" },
  { "name": "India", "code": "IN", "currencyCode": "INR", "symbol": "₹" },
  {
    "name": "British Indian Ocean Territory",
    "code": "IO",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Iraq", "code": "IQ", "currencyCode": "IQD", "symbol": "IQD" },
  { "name": "Iran", "code": "IR", "currencyCode": "IRR", "symbol": "IRR" },
  { "name": "Iceland", "code": "IS", "currencyCode": "ISK", "symbol": "ISK" },
  { "name": "Italy", "code": "IT", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Jersey", "code": "JE", "currencyCode": "GBP", "symbol": "£" },
  { "name": "Jamaica", "code": "JM", "currencyCode": "JMD", "symbol": "JMD" },
  { "name": "Jordan", "code": "JO", "currencyCode": "JOD", "symbol": "JOD" },
  { "name": "Japan", "code": "JP", "currencyCode": "JPY", "symbol": "¥" },
  { "name": "Kenya", "code": "KE", "currencyCode": "KES", "symbol": "KES" },
  {
    "name": "Kyrgyzstan",
    "code": "KG",
    "currencyCode": "KGS",
    "symbol": "KGS"
  },
  { "name": "Cambodia", "code": "KH", "currencyCode": "KHR", "symbol": "KHR" },
  { "name": "Kiribati", "code": "KI", "currencyCode": "AUD", "symbol": "A$" },
  { "name": "Comoros", "code": "KM", "currencyCode": "KMF", "symbol": "KMF" },
  {
    "name": "Saint Kitts and Nevis",
    "code": "KN",
    "currencyCode": "XCD",
    "symbol": "EC$"
  },
  {
    "name": "North Korea",
    "code": "KP",
    "currencyCode": "KPW",
    "symbol": "KPW"
  },
  { "name": "South Korea", "code": "KR", "currencyCode": "KRW", "symbol": "₩" },
  { "name": "Kuwait", "code": "KW", "currencyCode": "KWD", "symbol": "KWD" },
  {
    "name": "Cayman Islands",
    "code": "KY",
    "currencyCode": "KYD",
    "symbol": "KYD"
  },
  {
    "name": "Kazakhstan",
    "code": "KZ",
    "currencyCode": "KZT",
    "symbol": "KZT"
  },
  { "name": "Laos", "code": "LA", "currencyCode": "LAK", "symbol": "LAK" },
  { "name": "Lebanon", "code": "LB", "currencyCode": "LBP", "symbol": "LBP" },
  {
    "name": "Saint Lucia",
    "code": "LC",
    "currencyCode": "XCD",
    "symbol": "EC$"
  },
  {
    "name": "Liechtenstein",
    "code": "LI",
    "currencyCode": "CHF",
    "symbol": "CHF"
  },
  { "name": "Sri Lanka", "code": "LK", "currencyCode": "LKR", "symbol": "LKR" },
  { "name": "Liberia", "code": "LR", "currencyCode": "LRD", "symbol": "LRD" },
  { "name": "Lesotho", "code": "LS", "currencyCode": "LSL", "symbol": "LSL" },
  { "name": "Lithuania", "code": "LT", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Luxembourg", "code": "LU", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Latvia", "code": "LV", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Libya", "code": "LY", "currencyCode": "LYD", "symbol": "LYD" },
  { "name": "Morocco", "code": "MA", "currencyCode": "MAD", "symbol": "MAD" },
  { "name": "Monaco", "code": "MC", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Moldova", "code": "MD", "currencyCode": "MDL", "symbol": "MDL" },
  { "name": "Montenegro", "code": "ME", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Saint Martin",
    "code": "MF",
    "currencyCode": "EUR",
    "symbol": "€"
  },
  {
    "name": "Madagascar",
    "code": "MG",
    "currencyCode": "MGA",
    "symbol": "MGA"
  },
  {
    "name": "Marshall Islands",
    "code": "MH",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Macedonia", "code": "MK", "currencyCode": "MKD", "symbol": "MKD" },
  { "name": "Mali", "code": "ML", "currencyCode": "XOF", "symbol": "CFA" },
  {
    "name": "Myanmar [Burma]",
    "code": "MM",
    "currencyCode": "MMK",
    "symbol": "MMK"
  },
  { "name": "Mongolia", "code": "MN", "currencyCode": "MNT", "symbol": "MNT" },
  { "name": "Macao", "code": "MO", "currencyCode": "MOP", "symbol": "MOP" },
  {
    "name": "Northern Mariana Islands",
    "code": "MP",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Martinique", "code": "MQ", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Mauritania",
    "code": "MR",
    "currencyCode": "MRO",
    "symbol": "MRO"
  },
  {
    "name": "Montserrat",
    "code": "MS",
    "currencyCode": "XCD",
    "symbol": "EC$"
  },
  { "name": "Malta", "code": "MT", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Mauritius", "code": "MU", "currencyCode": "MUR", "symbol": "MUR" },
  { "name": "Maldives", "code": "MV", "currencyCode": "MVR", "symbol": "MVR" },
  { "name": "Malawi", "code": "MW", "currencyCode": "MWK", "symbol": "MWK" },
  { "name": "Mexico", "code": "MX", "currencyCode": "MXN", "symbol": "MX$" },
  { "name": "Malaysia", "code": "MY", "currencyCode": "MYR", "symbol": "MYR" },
  {
    "name": "Mozambique",
    "code": "MZ",
    "currencyCode": "MZN",
    "symbol": "MZN"
  },
  { "name": "Namibia", "code": "NA", "currencyCode": "NAD", "symbol": "NAD" },
  {
    "name": "New Caledonia",
    "code": "NC",
    "currencyCode": "XPF",
    "symbol": "CFPF"
  },
  { "name": "Niger", "code": "NE", "currencyCode": "XOF", "symbol": "CFA" },
  {
    "name": "Norfolk Island",
    "code": "NF",
    "currencyCode": "AUD",
    "symbol": "A$"
  },
  { "name": "Nigeria", "code": "NG", "currencyCode": "NGN", "symbol": "NGN" },
  { "name": "Nicaragua", "code": "NI", "currencyCode": "NIO", "symbol": "NIO" },
  { "name": "Netherlands", "code": "NL", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Norway", "code": "NO", "currencyCode": "NOK", "symbol": "NOK" },
  { "name": "Nepal", "code": "NP", "currencyCode": "NPR", "symbol": "NPR" },
  { "name": "Nauru", "code": "NR", "currencyCode": "AUD", "symbol": "A$" },
  { "name": "Niue", "code": "NU", "currencyCode": "NZD", "symbol": "NZ$" },
  {
    "name": "New Zealand",
    "code": "NZ",
    "currencyCode": "NZD",
    "symbol": "NZ$"
  },
  { "name": "Oman", "code": "OM", "currencyCode": "OMR", "symbol": "OMR" },
  { "name": "Panama", "code": "PA", "currencyCode": "PAB", "symbol": "PAB" },
  { "name": "Peru", "code": "PE", "currencyCode": "PEN", "symbol": "PEN" },
  {
    "name": "French Polynesia",
    "code": "PF",
    "currencyCode": "XPF",
    "symbol": "CFPF"
  },
  {
    "name": "Papua New Guinea",
    "code": "PG",
    "currencyCode": "PGK",
    "symbol": "PGK"
  },
  {
    "name": "Philippines",
    "code": "PH",
    "currencyCode": "PHP",
    "symbol": "PHP"
  },
  { "name": "Pakistan", "code": "PK", "currencyCode": "PKR", "symbol": "PKR" },
  { "name": "Poland", "code": "PL", "currencyCode": "PLN", "symbol": "PLN" },
  {
    "name": "Saint Pierre and Miquelon",
    "code": "PM",
    "currencyCode": "EUR",
    "symbol": "€"
  },
  {
    "name": "Pitcairn Islands",
    "code": "PN",
    "currencyCode": "NZD",
    "symbol": "NZ$"
  },
  { "name": "Puerto Rico", "code": "PR", "currencyCode": "USD", "symbol": "$" },
  { "name": "Palestine", "code": "PS", "currencyCode": "ILS", "symbol": "₪" },
  { "name": "Portugal", "code": "PT", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Palau", "code": "PW", "currencyCode": "USD", "symbol": "$" },
  { "name": "Paraguay", "code": "PY", "currencyCode": "PYG", "symbol": "PYG" },
  { "name": "Qatar", "code": "QA", "currencyCode": "QAR", "symbol": "QAR" },
  { "name": "Réunion", "code": "RE", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Romania", "code": "RO", "currencyCode": "RON", "symbol": "RON" },
  { "name": "Serbia", "code": "RS", "currencyCode": "RSD", "symbol": "RSD" },
  { "name": "Russia", "code": "RU", "currencyCode": "RUB", "symbol": "RUB" },
  { "name": "Rwanda", "code": "RW", "currencyCode": "RWF", "symbol": "RWF" },
  {
    "name": "Saudi Arabia",
    "code": "SA",
    "currencyCode": "SAR",
    "symbol": "SAR"
  },
  {
    "name": "Solomon Islands",
    "code": "SB",
    "currencyCode": "SBD",
    "symbol": "SBD"
  },
  {
    "name": "Seychelles",
    "code": "SC",
    "currencyCode": "SCR",
    "symbol": "SCR"
  },
  { "name": "Sudan", "code": "SD", "currencyCode": "SDG", "symbol": "SDG" },
  { "name": "Sweden", "code": "SE", "currencyCode": "SEK", "symbol": "SEK" },
  { "name": "Singapore", "code": "SG", "currencyCode": "SGD", "symbol": "SGD" },
  {
    "name": "Saint Helena",
    "code": "SH",
    "currencyCode": "SHP",
    "symbol": "SHP"
  },
  { "name": "Slovenia", "code": "SI", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Svalbard and Jan Mayen",
    "code": "SJ",
    "currencyCode": "NOK",
    "symbol": "NOK"
  },
  { "name": "Slovakia", "code": "SK", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "Sierra Leone",
    "code": "SL",
    "currencyCode": "SLL",
    "symbol": "SLL"
  },
  { "name": "San Marino", "code": "SM", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Senegal", "code": "SN", "currencyCode": "XOF", "symbol": "CFA" },
  { "name": "Somalia", "code": "SO", "currencyCode": "SOS", "symbol": "SOS" },
  { "name": "Suriname", "code": "SR", "currencyCode": "SRD", "symbol": "SRD" },
  {
    "name": "South Sudan",
    "code": "SS",
    "currencyCode": "SSP",
    "symbol": "SSP"
  },
  {
    "name": "São Tomé and Príncipe",
    "code": "ST",
    "currencyCode": "STD",
    "symbol": "STD"
  },
  { "name": "El Salvador", "code": "SV", "currencyCode": "USD", "symbol": "$" },
  {
    "name": "Sint Maarten",
    "code": "SX",
    "currencyCode": "ANG",
    "symbol": "ANG"
  },
  { "name": "Syria", "code": "SY", "currencyCode": "SYP", "symbol": "SYP" },
  { "name": "Swaziland", "code": "SZ", "currencyCode": "SZL", "symbol": "SZL" },
  {
    "name": "Turks and Caicos Islands",
    "code": "TC",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Chad", "code": "TD", "currencyCode": "XAF", "symbol": "FCFA" },
  {
    "name": "French Southern Territories",
    "code": "TF",
    "currencyCode": "EUR",
    "symbol": "€"
  },
  { "name": "Togo", "code": "TG", "currencyCode": "XOF", "symbol": "CFA" },
  { "name": "Thailand", "code": "TH", "currencyCode": "THB", "symbol": "THB" },
  {
    "name": "Tajikistan",
    "code": "TJ",
    "currencyCode": "TJS",
    "symbol": "TJS"
  },
  { "name": "Tokelau", "code": "TK", "currencyCode": "NZD", "symbol": "NZ$" },
  { "name": "East Timor", "code": "TL", "currencyCode": "USD", "symbol": "$" },
  {
    "name": "Turkmenistan",
    "code": "TM",
    "currencyCode": "TMT",
    "symbol": "TMT"
  },
  { "name": "Tunisia", "code": "TN", "currencyCode": "TND", "symbol": "TND" },
  { "name": "Tonga", "code": "TO", "currencyCode": "TOP", "symbol": "TOP" },
  { "name": "Turkey", "code": "TR", "currencyCode": "TRY", "symbol": "TRY" },
  {
    "name": "Trinidad and Tobago",
    "code": "TT",
    "currencyCode": "TTD",
    "symbol": "TTD"
  },
  { "name": "Tuvalu", "code": "TV", "currencyCode": "AUD", "symbol": "A$" },
  { "name": "Taiwan", "code": "TW", "currencyCode": "TWD", "symbol": "NT$" },
  { "name": "Tanzania", "code": "TZ", "currencyCode": "TZS", "symbol": "TZS" },
  { "name": "Ukraine", "code": "UA", "currencyCode": "UAH", "symbol": "UAH" },
  { "name": "Uganda", "code": "UG", "currencyCode": "UGX", "symbol": "UGX" },
  {
    "name": "U.S. Minor Outlying Islands",
    "code": "UM",
    "currencyCode": "USD",
    "symbol": "$"
  },
  {
    "name": "United States",
    "code": "US",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Uruguay", "code": "UY", "currencyCode": "UYU", "symbol": "UYU" },
  {
    "name": "Uzbekistan",
    "code": "UZ",
    "currencyCode": "UZS",
    "symbol": "UZS"
  },
  {
    "name": "Vatican City",
    "code": "VA",
    "currencyCode": "EUR",
    "symbol": "€"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "VC",
    "currencyCode": "XCD",
    "symbol": "EC$"
  },
  { "name": "Venezuela", "code": "VE", "currencyCode": "VEF", "symbol": "VEF" },
  {
    "name": "British Virgin Islands",
    "code": "VG",
    "currencyCode": "USD",
    "symbol": "$"
  },
  {
    "name": "U.S. Virgin Islands",
    "code": "VI",
    "currencyCode": "USD",
    "symbol": "$"
  },
  { "name": "Vietnam", "code": "VN", "currencyCode": "VND", "symbol": "₫" },
  { "name": "Vanuatu", "code": "VU", "currencyCode": "VUV", "symbol": "VUV" },
  {
    "name": "Wallis and Futuna",
    "code": "WF",
    "currencyCode": "XPF",
    "symbol": "CFPF"
  },
  { "name": "Samoa", "code": "WS", "currencyCode": "WST", "symbol": "WST" },
  { "name": "Kosovo", "code": "XK", "currencyCode": "EUR", "symbol": "€" },
  { "name": "Yemen", "code": "YE", "currencyCode": "YER", "symbol": "YER" },
  { "name": "Mayotte", "code": "YT", "currencyCode": "EUR", "symbol": "€" },
  {
    "name": "South Africa",
    "code": "ZA",
    "currencyCode": "ZAR",
    "symbol": "ZAR"
  },
  { "name": "Zambia", "code": "ZM", "currencyCode": "ZMW", "symbol": "ZMW" },
  { "name": "Zimbabwe", "code": "ZW", "currencyCode": "ZWL", "symbol": "ZWL" }
]


